<template>
  <div style="width:100%">
  <v-dialog v-model="dialogo" fullscreen persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark :color="colores.secundario">
        <v-btn icon dark @click="cerrarVenta"><v-icon>mdi-close</v-icon></v-btn>
        <v-toolbar-title>CORREO</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="downloadEmail" icon><v-icon>mdi-email</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="pt-4">
        <correoFrame :tokenCorreo="tokenCorreo" :colores="colores" :id_proyecto="id_proyecto" :id_correo="id_correo"></correoFrame>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
// import correoFrame from '@/components/correos/correo_frameComponent.vue'
import recCorreos from '@/js/rec_correos.js'
export default {
  name: 'correos_dialogoVerComponent',
  components: {
    correoFrame: () => import('@/components/correos/correo_frameComponent.vue')
  },
  mixins: [recCorreos],
  props: ['dialogo', 'tokenCorreo', 'colores', 'id_proyecto', 'id_correo'],
  data: () => ({
  }),
  methods: {
    downloadEmail () {
      this.rec_correos_download(this.id_correo)
    },
    cerrarVenta () {
      this.$emit('closed_correo')
    }
  }
}
</script>
